import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`


*{
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,200&display=swap');
body {
  
  font-family: 'Fira Sans', sans-serif;

  margin: 0 auto;
 
  a{
    text-decoration: none;
    color: ${({ theme }) => theme.colors.buttonColor};

    &:hover{
      color: red;
    }
  }
}

`;

export default GlobalStyles;
