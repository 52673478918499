import styled from "styled-components";

export const UiToggle = styled.div`

    margin: 7px 0;
    padding: 0;
  

   input[type="checkbox"],
   input[type="radio"] {
    display: none;
  }

   input[type="checkbox"]:checked + label,
   input[type="radio"]:checked + label {
    border-color: ${({ theme }) => theme.colors.buttonColor};
    background: ${({ theme }) => theme.colors.buttonColor};
    box-shadow: inset 0 0 0 10px ${({ theme }) => theme.colors.buttonColor};
  }

   input[type="checkbox"]:checked + label > div,
   input[type="radio"]:checked + label > div {
    margin-left: 20px;
  }

   label {
    transition: all 200ms ease;
    display: inline-block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #8c8c8c;
    box-shadow: inset 0 0 0 0 ${({ theme }) => theme.colors.buttonColor};
    border: 2px solid #8c8c8c;
    border-radius: 22px;
    width: 44px;
    height: 22px;
  }

   label div {
    transition: all 200ms ease;
    background: #fff;
    width: 20px;
    height: 18px;
    border-radius: 10px;
  }

   label:hover,
   label > div:hover {
    cursor: pointer;
  }


`;
