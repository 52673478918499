import styled from "styled-components";

export const BodyContainer = styled.section`
  width: ${({ theme }) => theme.desktop};
  max-width: 100%;
  margin: 0 auto auto;

  min-height: ${({ theme }) => (theme.path !== "/login" ? "100vh" : "70vh")};

  margin-top: ${({ theme }) => (theme.path !== "/login" ? "0" : "15vh")};

  position: relative;
`;
