import React, { useState, useContext } from "react";
import { TextInput } from "../../component/Styles/Input.Styled";
import { useNavigate } from "react-router-dom";
import userService from "../../Appservices/Appservice";
import { ButtonStyled } from "../../component/Styles/Button.Styled";
import { UiToggle } from "../../component/Styles/Uitoggle.Styled";
import DispatchContext from "../../Appcontext/DispatchContext";

const AddJobs = () => {
  const navigate = useNavigate();
  const appDispatch = useContext(DispatchContext);

  const initialjobsState = {
    id: null,
    title: "",
    issend: false,
    interview: false,
    islogged: false,
    unsolicited: false,
    appl_deadline: "",
    joblink: "",
  };
  const [jobs, setJobs] = useState(initialjobsState);
  const [submitted, setSubmitted] = useState(false);

  const [message, setMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setJobs({ ...jobs, [name]: value });
    prepareJobs();
  };

  const prepareJobs = () => {
    const response = userService.post("joblinkexsist", jobs.joblink);

    console.log(response);
  };

  const savejobs = () => {
    var data = {
      title: jobs.title,
      joblink: jobs.joblink,
      appl_deadline: jobs.appl_deadline,
      appl_added: jobs.appl_added,
      interview: false,
      issend: false,
      islogged: false,
      unsolicited: jobs.unsolicited,
    };

    userService
      .create("newjob", data)
      .then((response) => {
        setJobs({
          id: response.data.id,
          title: response.data.title,
          joblink: response.data.joblink,
          interview: response.data.interview,
          unsolicited: response.data.unsolicited,
          issend: response.data.issend,
          islogged: response.data.islogged,
          appl_deadline: response.data.appl_deadline,
          appl_added: response.data.appl_added,
        });

        appDispatch({ type: "ToggleModal", value: "none" });
        appDispatch({ type: "submitCount" });
        navigate(`/jobs`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const newjobs = () => {
    setJobs(initialjobsState);
    setSubmitted(false);
  };

  return (
    <section>
      {submitted ? (
        <div>
          <h4>Jobbet blev gemt</h4>
          <button onClick={newjobs}>Tilføj nyt job</button>
        </div>
      ) : (
        <div>
          <div>
            <label htmlFor="title">Title</label>
            <TextInput type="text" id="title" required value={jobs.title} onChange={handleInputChange} name="title" />
          </div>

          <div>
            <label htmlFor="joblink">joblink</label>
            <TextInput type="text" id="joblink" required value={jobs.joblink} onChange={handleInputChange} name="joblink" />
          </div>

          <div>
            uopfordret
            <UiToggle>
              <TextInput
                type="checkbox"
                id="unsolicited"
                name="unsolicited"
                value={jobs.unsolicited}
                onChange={() => (jobs.unsolicited = !jobs.unsolicited)}
              />
              <label htmlFor="unsolicited">
                <div></div>
              </label>
            </UiToggle>
          </div>

          <div>
            <label htmlFor="appl_added">Indrykket d. - undlad at udfylde hvis ukendt</label>
            <TextInput type="date" id="appl_added" value={jobs.appl_added} onChange={handleInputChange} name="appl_added" />
          </div>

          <div>
            <label htmlFor="appl_deadline">Ansøgningsfrist - undlad at udfylde hvis ukendt</label>
            <TextInput type="date" id="appl_deadline" value={jobs.appl_deadline} onChange={handleInputChange} name="appl_deadline" />
          </div>
          {message}
          <ButtonStyled type="submit" onClick={savejobs}>
            Gem Job
          </ButtonStyled>
        </div>
      )}
    </section>
  );
};

export default AddJobs;
