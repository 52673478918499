import styled from "styled-components";
import { BoxShadow } from "./Mixins";

const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  margin: 10px 0;
  background: ${({ theme }) => theme.colors.body};
  border: none;

  border-radius: 6px;

  border: solid 1px ${({ theme }) => theme.colors.footerBottom};

  width: 100% !important;

  ::placeholder {
    color: ${({ theme }) => theme.colors.footerBottom};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 10px 0px;
  }
`;

export const TextInput = styled(Input)``;

export const SubmitInput = styled(Input)`
  ${BoxShadow};
  cursor: pointer;
  text-align: center;

  background-color: ${({ theme }) => theme.colors.footerBottom};
  color: #fff;
  &:hover {
    filter: brightness(80%);
  }
`;
