import React from "react";

import AppProvider from "./AppProvider/AppProvider";
import AppRouter from "./AppRouter/AppRouter";
import GlobalStyles from "./component/Styles/Global";
import Modal from "./component/Modal";

function App() {
  return (
    <AppProvider>
      <GlobalStyles />
      <Modal />
      <AppRouter />
    </AppProvider>
  );
}

export default App;
