import React, { lazy, Suspense, useState, useEffect, useContext } from "react";
import userService from "../../Appservices/Appservice";
import DispatchContext from "../../Appcontext/DispatchContext";
import { TextInput } from "../../component/Styles/Input.Styled";
import { UiToggle } from "../../component/Styles/Uitoggle.Styled";

import moment from "moment";
import "moment/locale/da";
import { ButtonStyled } from "../../component/Styles/Button.Styled";


const Jobs = (props) => {
  const appDispatch = useContext(DispatchContext);

  const id = props.id;

  const initialJobState = {
    id: null,
    title: "",
    interview: false,
    issend: false,
    islogged: false,
    unsolicited: false,
    appl_deadline: "",
    appl_added: "",
    joblink: "",
  };
  const [currentJob, setCurrentJob] = useState(initialJobState);
  const [message, setMessage] = useState("");

  const getJob = (id) => {
    userService
      .get("job", id)
      .then((response) => {
        setCurrentJob(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getJob(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentJob({ ...currentJob, [name]: value });
  };

  const updateJobs = () => {
    userService
      .update("job", currentJob.id, currentJob)
      .then((response) => {
        appDispatch({ type: "ToggleModal", value: "none" });
        props.retrieveJobs();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updatePublished = (input, status) => {
    var data = {
      [input]: status,
    };

    userService
      .update("job", currentJob.id, data)
      .then((response) => {
        setCurrentJob({ ...currentJob, [input]: status });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <section>
      {currentJob ? (
        <div>
          <h4>Jobs</h4>
          <form>
            <div>
              <label htmlFor="title">Title</label>
              <TextInput type="text" id="title" name="title" value={currentJob.title} onChange={handleInputChange} />
            </div>
            <div>
              <label htmlFor="joblink">joblink</label>
              <TextInput type="text" id="joblink" name="joblink" value={currentJob.joblink} onChange={handleInputChange} />
            </div>

            <div>
              <label htmlFor="appl_added">Indrykket - undlad at udfylde hvis ukendt</label>
              <TextInput
                type="date"
                id="appl_added"
                value={moment(currentJob.appl_added).locale("en").format("YYYY-MM-DD")}
                onChange={handleInputChange}
                name="appl_added"
              />
            </div>

            <div>
              <label htmlFor="appl_deadline">Ansøgningsfrist - undlad at udfylde hvis ukendt</label>
              <TextInput
                type="date"
                id="appl_deadline"
                value={moment(currentJob.appl_deadline).locale("en").format("YYYY-MM-DD")}
                onChange={handleInputChange}
                name="appl_deadline"
              />
            </div>

            <div>
              Indkaldt til job interview
              <UiToggle>
                <TextInput
                  type="checkbox"
                  id="interview"
                  name="interview"
                  value={currentJob.interview}
                  checked={currentJob.interview}
                  onChange={() => updatePublished("interview", !currentJob.interview)}
                />
                <label htmlFor="interview">
                  <div></div>
                </label>
              </UiToggle>
            </div>

            <div>
              uopfordret
              <UiToggle>
                <TextInput
                  type="checkbox"
                  id="unsolicited"
                  name="unsolicited"
                  value={currentJob.unsolicited}
                  checked={currentJob.unsolicited}
                  onChange={() => updatePublished("unsolicited", !currentJob.unsolicited)}
                />
                <label htmlFor="unsolicited">
                  <div></div>
                </label>
              </UiToggle>
            </div>
            <div>
              Er ansøgt
              <UiToggle>
                <TextInput
                  type="checkbox"
                  id="issend"
                  name="issend"
                  value={currentJob.issend}
                  checked={currentJob.issend}
                  onChange={() => updatePublished("issend", !currentJob.issend)}
                />
                <label htmlFor="issend">
                  <div></div>
                </label>
              </UiToggle>
            </div>
            <div>
              Er joblogged
              <UiToggle>
                <TextInput
                  type="checkbox"
                  id="islogged"
                  name="islogged"
                  value={currentJob.islogged}
                  checked={currentJob.islogged}
                  onChange={() => updatePublished("islogged", !currentJob.islogged)}
                />
                <label htmlFor="islogged">
                  <div></div>
                </label>
              </UiToggle>
            </div>
          </form>

          <ButtonStyled type="submit" onClick={updateJobs}>
            Opdater
          </ButtonStyled>
          <ButtonStyled onClick={() => appDispatch({ type: "ToggleModal", value: "none" })}>Annuller</ButtonStyled>

          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Jobindlægget er opdateret...</p>
        </div>
      )}
    </section>
  );
};

export default Jobs;
