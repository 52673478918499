import React, { useEffect, Suspense, lazy, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { BodyContainer } from "../component/Styles/Container.styled";
import Jobs from "../Pages/jobs/Jobs";
import AddJobs from "../Pages/jobs/AddJobs";

import StateContext from "../Appcontext/StateContext";
import DispatchContext from "../Appcontext/DispatchContext";
const JobsList = lazy(() => import("../Pages/jobs/JobsList"));
const Header = lazy(() => import("../component/Header"));
const Footer = lazy(() => import("../component/Footer"));
const Notfound = lazy(() => import("../Pages/Notfound"));
const Login = lazy(() => import("../Pages/Login"));

const AppRouter = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  function handleWindowSizeChange() {
    appDispatch({ type: "isMobile", value: window.innerWidth <= 768 ? true : false });
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BodyContainer>
        <Header />
        <Routes>
          <Route path="/" element={appState.loggedIn ? <JobsList /> : <Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/jobs" element={appState.loggedIn ? <JobsList /> : <Login />} />

          <Route path="/job/add" element={appState.loggedIn ? <AddJobs /> : <Login />} />
          <Route path="/jobs/:id" element={appState.loggedIn ? <Jobs /> : <Login />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        <Footer />
      </BodyContainer>
    </Suspense>
  );
};

export default AppRouter;
