export const BoxShadow = () => {
  return `box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;`;
};

export const DefaultHeaderTagStyling = () => {
  return `

  font-weight: 900;
  text-transform: uppercase;

  margin: 0 0 20px 0;
  line-height: 1.5;
  
  `;
};
