import React, { useContext } from "react";
import { ModalStyled } from "./Styles/Modal.Styled";

import DispatchContext from "../Appcontext/DispatchContext";
import StateContext from "../Appcontext/StateContext";

const Modal = (props) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  return (
    <ModalStyled>
      <div>
        <span onClick={() => appDispatch({ type: "ToggleModal", value: "none" })}>&times;</span>
        {appState.theme.ModalPayload}
      </div>
    </ModalStyled>
  );
};

export default Modal;
