import React from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { useImmerReducer } from "use-immer";

import DispatchContext from "../Appcontext/DispatchContext";
import StateContext from "../Appcontext/StateContext";

const AppProvider = ({ children }) => {
  const { pathname } = useLocation();

  const initialState = {
    theme: {
      colors: {
        header: "#ff00cc",
        body: "#ffffff",
        footerTop: "#252526",
        footerBottom: "#325254",
        listHeader: "#325254",
        buttonColor: "#325254",
      },
      logo: "60px",
      landingpageHeight: "70vh",
      desktop: "1200px",
      mobile: "768px",
      path: pathname,
      showBurger: false,
      showmodal: "none",
      ModalPayload: "",
    },
    IsMobile: false,
    loggedIn: Boolean(localStorage.getItem("token")),
    user: JSON.parse(localStorage.getItem("user")),
    roles: [],
    deviceses: [],
    token: JSON.parse(localStorage.getItem("token")),
    uid: { value: "", hasErrors: false, message: "", checkCount: 0 },
    password: { value: "", hasErrors: false, message: "", checkCount: 0 },
    submitCount: 0,
    Approutes: [],
  };

  const ourReducer = (draft, action) => {
    switch (action.type) {
      case "pushRoutes":
        draft.Approutes = [];
        action.value.forEach((e) => {
          draft.Approutes.push(e);
        });
        return;

      case "isMobile":
        draft.IsMobile = action.value;
        return;

      case "ToggleModal":
        draft.theme.showmodal = action.value;

        draft.theme.ModalPayload = action.payload;
        return;

      case "toggleBurger":
        draft.theme.showBurger = !draft.theme.showBurger;
        return;

      case "scrollHome":
        return document.getElementById("dashboardMain").scrollIntoView({
          block: "center",
        });

      case "addRoles":
        console.log(action.value);
        return;

      case "uidImmediatly":
        draft.uid.hasErrors = false;
        draft.uid.value = action.value;
        if (!draft.uid.value) {
          draft.uid.hasErrors = true;
          draft.uid.message = "Brugernavn eller emailadresse er påkrævet";
        }

        return;

      case "passwordImmediatly":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (!draft.password.value) {
          draft.password.hasErrors = true;
          draft.password.message = "Password er påkrævet";
        }
        return;

      case "submitCount":
        draft.submitCount++;
        return;

      case "submitForm":
        if (!draft.uid.hasErrors && !draft.password.hasErrors) {
          draft.submitCount++;
        }

        return;

      case "login":
        draft.loggedIn = true;

        return;

      case "logout":
        draft.loggedIn = false;
        draft.user = "";
        draft.uid.value = "";
        draft.password.value = "";
        draft.submitCount = 0;
        localStorage.removeItem("token");
        localStorage.removeItem("user");

        return;

      default:
        return;
    }
  };

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={state.theme}>{children}</ThemeProvider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default AppProvider;
