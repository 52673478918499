import styled from "styled-components";

export const ButtonStyled = styled.button`
  display: inline-flex;
  align-items: center;

  padding: 8px;
  border-radius: 6px;

  svg {
    margin-left: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.buttonColor};
    color: #fff;
    cursor: pointer;
  }
`;
